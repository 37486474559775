<template>
  <section>
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <!-- <b-breadcrumb-item :to="{ name: 'browse-promotions' }">
              Promotions
            </b-breadcrumb-item> -->
            <b-breadcrumb-item :active="true">
              Manage Promotions
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2">
          <feather-icon
            icon="StarIcon"
            size="24"
          />
          Manage Promotions
        </h2>
      </b-col>
      <!-- <b-col
        md="6"
        class="mb-2 text-right pt-2"
      >
        <b-button
          variant="primary"
          :to="{ name: 'create-promotion' }"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="align-text-top"
          />  Add New
        </b-button>
      </b-col> -->
    </b-row>

    <div
      id="addPromotionDrawer"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="addPromotionDrawerLabel"
    >
      <b-card
        title="Current Promotion"
        class="pb-auto"
      >
        {{ currentPromotion }}
      </b-card>

      <b-card
        title="Edit Promotion"
        class="pb-auto"
      >
        <validation-observer
          ref="addPromotionForm"
          #default="{invalid, dirty}"
        >
          <b-form
            class="form-container"
            @submit.prevent="submitPromo"
          >
            <b-row>
              <b-col>
                <!-- promotional text -->
                <b-form-group
                  label="Promotional Text"
                  label-for="promotionText"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Promotional Text"
                    rules="required"
                    vid="promotionText"
                  >
                    <b-form-textarea
                      id="promotionText"
                      v-model="promotionForm.text"
                      type="text"
                      class="form-control"
                      placeholder="Promotional Text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                class="mt-2 text-right"
              >
                <!-- reset button -->
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mr-1"
                >
                  Reset
                </b-button>

                <!-- action button -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="invalid || !dirty"
                >
                  Submit Promotion
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </b-card>
    </div>

  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem,
  BRow, BCol, BButton, BForm, BFormGroup, BFormTextarea, BCard,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
    BCard,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      message: '',
      currentPromotion: null,
      promotionForm: {},

      // validation
      required,
      email,
    }
  },
  created() {
    this.getPromotionalCopy()
  },
  methods: {
    getPromotionalCopy() {
      // get existing promo copy
      useJwt.getPromoCopy()
        .then(promoResponse => {
          const promoData = promoResponse.data.data
          console.log('promoData', promoData) // DELETE LATER
          this.currentPromotion = promoData
          this.$set(this.promotionForm, 'text', promoData)
        })
        .catch(error => {
          console.log('error', error)
          if (error.response.status === 403) { // forbidden
            // this.$router.push({ name: 'error-404' })
          } else if (error.response.status === 404) { // not found
            // this.$router.push({ name: 'error-404' })
          }
        })
    },
    submitPromo() {
      useJwt.createPromoCopy(this.promotionForm)
        .then(res => {
          const responseCode = res.status
          const responseText = res.statusText

          console.log('createPromoCopy', res)
          console.log('Server reponse: ', responseCode, responseText)
          // this.message = `${responseCode} ${responseText}`
          this.message = `${responseText}`

          // Refresh page and show notification
          this.$router.go()
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Promotion Created',
                  icon: 'PromotionPlusIcon',
                  variant: 'success',
                  text: `You have created a new promotion: "${this.promotionForm.text}"`,
                },
              })
            })
        })
        .catch(err => {
          // console.dir(err.response)
          console.error('createPromoCopy ERROR', err.response.status, err.response.statusText)

          // Error notification on failure
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Promotion ${err.statusText}`,
              text: `Error: ${err.response.status} ${err.response.statusText}.`,
              icon: 'ExclamationTriangle',
              variant: 'error',
            },
          })
        })
    },
  },
}
</script>
